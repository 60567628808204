<template>
    <v-container fluid>
  
  
      <v-card class="rounded-lg overflow-hidden">
        <v-card-title>
          <v-icon left color="primary">mdi-email</v-icon>
          Notificaciones
  
          <v-spacer></v-spacer>
          <v-btn class="rounded-lg" color="primary" @click="newTemplate"
            v-if="$store.state.Auth.token.claims.type == 'superuser'">
            <v-icon left>mdi-plus</v-icon>
            Agregar
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
  
          <v-data-table :loading="loading" :headers="headers" :items="templates" item-key="id" class="elevation-1"
            mobile-breakpoint="0">
            <template v-slot:item.actions="{ item }">
              <v-btn elevation="0" fab x-small @click="editTemplate(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
  
              <v-btn elevation="0" class="ml-2" fab x-small color="red" @click="deleteTemplate(item.id)"
                v-if="$store.state.Auth.token.claims.type == 'superuser'">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
  
  
            <template v-slot:item.id="{ item }">{{ trans(item.id) }}</template>
  
            <template v-slot:item.status="{ item }">
              <v-chip color="green" v-if="item.status">Activo</v-chip>
              <v-chip color="red" v-else>Inactivo</v-chip>
            </template>
  
          </v-data-table>
        </v-card-text>
      </v-card>
  
  
      <v-dialog v-model="dialog" max-width="1000px" persistent>
        <v-card>
          <v-card-title class="pt-0">
            {{ currentTemplate.id ? 'Editar' : 'Nuevo' }} Template
            <v-spacer></v-spacer>
  
            <v-checkbox v-model="currentTemplate.status" label="Activo"></v-checkbox>
          </v-card-title>
          <v-card-text>
  
            <v-form ref="form">
              <v-text-field hide-details="" filled dense v-if="$store.state.Auth.token.claims.type == 'superuser'"
                :disabled="edit" v-model="currentTemplate.id" label="ID" :rules="[rules.required]"></v-text-field>
  
              <v-select hide-details="" filled dense v-model="currentTemplate.to" label="Destinatario" :items="toOptions"
                :rules="[rules.required]"></v-select>
  
              <v-text-field hide-details="" filled dense v-model="currentTemplate.title" label="Asunto"
                :rules="[rules.required]"></v-text-field>
  
              <v-combobox dense hide-details="" filled :search-input.sync="search"
                v-model="currentTemplate.replaceVariables" label="Variables de reemplazo"
                :items="currentTemplate.replaceVariables" multiple chips>
                <template v-slot:no-data>
                  <v-list-item @click="addVariable">
                    <v-list-item-content>
                      <v-list-item-title>
                        Presione <kbd>enter</kbd> para agregar.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template></v-combobox>
  
  
            </v-form>
            <WYSIWYGeditorVue v-model="currentTemplate.html" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="closeDialog" :disabled="loading">Cancelar</v-btn>
  
            <v-spacer></v-spacer>
            <v-btn color="success" @click="saveTemplate" :loading="loading">
              <v-icon left>
                mdi-check
              </v-icon>
              Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Add confirmation dialog -->
      <v-dialog v-model="confirmDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Confirmar eliminación
          </v-card-title>
          <v-card-text>
            ¿Está seguro que desea eliminar este template?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="confirmDialog = false" :disabled="loading">
              Cancelar
            </v-btn>
            <v-btn color="red" text @click="confirmDelete" :loading="loading">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import WYSIWYGeditorVue from '@/components/editor/WYSIWYGeditor.vue';
  import { collection, getFirestore, onSnapshot, addDoc, doc, updateDoc, deleteDoc, setDoc, getDocs } from 'firebase/firestore';
  
  export default {
    components: {
      WYSIWYGeditorVue,
    },
    data() {
      return {
        search: "",
        loading: true,
        headers: [
          { text: 'Tipo', value: 'id' },
          { text: 'Asunto', value: 'title' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', value: 'actions', sortable: false },
  
        ],
  
        templates: [],
        dialog: false,
        currentTemplate: {
          id: '',
          title: '',
          html: '',
          status: false,
          replaceVariables: [],
          to: 'cliente'
        },
        rules: {
          required: value => !!value || 'Required.',
        },
        edit: false,
        toOptions: [
          { text: 'Cliente', value: 'cliente' },
          { text: 'Administrador', value: 'administrador' }
        ],
        confirmDialog: false,
        templateToDelete: null,
      };
    },
    methods: {
      fetchTemplates() {
        const db = getFirestore();
        const templatesCol = collection(db, '/configurations/email/templates');
        /*  onSnapshot(templatesCol, (snapshot) => {
           this.templates = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
             this.loading = false;
         }); */
  
        getDocs(templatesCol).then((snapshot) => {
          this.templates = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          this.loading = false;
        });
      },
      newTemplate() {
        this.edit = false;
        this.currentTemplate = { id: null, title: '', html: '', status: false, replaceVariables: [], to: 'cliente' };
        this.dialog = true;
      },
      editTemplate(template) {
        this.edit = true;
        this.currentTemplate = { ...template };
        this.dialog = true;
      },
      saveTemplate() {
        if (!this.$refs.form.validate()) {
          return;
        }
  
        this.loading = true;
        const db = getFirestore();
  
        if (this.edit) {
          const templateRef = doc(db, '/configurations/email/templates', this.currentTemplate.id);
          updateDoc(templateRef, {
            title: this.currentTemplate.title,
            html: this.currentTemplate.html,
            status: this.currentTemplate.status,
            replaceVariables: this.currentTemplate.replaceVariables,
            to: this.currentTemplate.to
          }).then(() => {
            this.loading = false;
            this.closeDialog();
            this.$notify({
              type: 'success',
              title: 'Template guardado',
              text: 'Guardado correctamente',
            });
          });
  
  
          //update template on list
          this.templates = this.templates.map(template => {
            if (template.id === this.currentTemplate.id) {
              return { ...this.currentTemplate };
            }
            return template;
          });
  
        } else {
          // Add a new document with custom ID
          setDoc(doc(db, '/configurations/email/templates', this.currentTemplate.id), {
            title: this.currentTemplate.title,
            html: this.currentTemplate.html,
            status: this.currentTemplate.status,
            replaceVariables: this.currentTemplate.replaceVariables,
            to: this.currentTemplate.to
          }).then(() => {
  
            //add new template to list
  
            this.templates.push({ ...this.currentTemplate });
  
            this.loading = false;
            this.closeDialog();
            this.$notify({
              type: 'success',
              title: 'Template guardado',
              text: 'Guardado correctamente',
            });
          });
        }
      },
      deleteTemplate(id) {
        this.templateToDelete = id;
        this.confirmDialog = true;
      },
      confirmDelete() {
        this.loading = true;
        const db = getFirestore();
        deleteDoc(doc(db, '/configurations/email/templates', this.templateToDelete)).then(() => {
          this.templates = this.templates.filter(template => template.id !== this.templateToDelete);
          this.loading = false;
          this.confirmDialog = false;
          this.templateToDelete = null;
          this.$notify({
            type: 'success',
            title: 'Template eliminado',
            text: 'Eliminado correctamente',
          });
        });
      },
      closeDialog() {
        this.dialog = false;
      },
      trans(id) {
  
        if (id == 'welcome') return "Bienvenida";
        if (id == 'reminder') return "Recordatorio de suscripción";
        if (id == 'expired') return "Renovación de suscripción";
        if (id == 'survey_sentiment') return "Encuesta de satisfacción - General";
        if (id == 'workout_sentiment') return "Encuesta de satisfacción - Entrenamiento";
  
  
        return id;
      },
      addVariable() {
        this.currentTemplate.replaceVariables.push(this.search);
        this.search = null;
      }
    },
    created() {
      this.fetchTemplates();
    },
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>